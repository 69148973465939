import {css} from "styled-components";
import {COLORS, THEMES} from "../common/constants";
import { removeVarFromCSS } from "../common/utils";


const DarkThemeColors = css`
  ${removeVarFromCSS(COLORS.PRIMARY.d1)}: #D0FBE8;
  ${removeVarFromCSS(COLORS.PRIMARY.d2)}: #A2F7D2;
  ${removeVarFromCSS(COLORS.PRIMARY.d3)}: #73F3BC;
  ${removeVarFromCSS(COLORS.PRIMARY.d4)}: #45EFA5;
  ${removeVarFromCSS(COLORS.PRIMARY.d5)}: #16EB8F;
  ${removeVarFromCSS(COLORS.BACKGROUND.d1)}: #12303C;
  ${removeVarFromCSS(COLORS.BACKGROUND.d2)}: #07131C;
  ${removeVarFromCSS(COLORS.BACKGROUND.d3)}: #000000;
  ${removeVarFromCSS(COLORS.BACKGROUND.gradient)}: transparent linear-gradient(180deg, ${COLORS.BACKGROUND.d3} 0%, ${COLORS.BACKGROUND.d1} 100%);
  ${removeVarFromCSS(COLORS.GRAY.d1)}: #CACCD5;
  ${removeVarFromCSS(COLORS.GRAY.d2)}: #838996;
  ${removeVarFromCSS(COLORS.WARNING)}: #FEC132;
  ${removeVarFromCSS(COLORS.INFO)}: #58C2D9;
  ${removeVarFromCSS(COLORS.DANGER)}: #E64965;
  ${removeVarFromCSS(COLORS.SUCCESS)}: #58D96D;
  ${removeVarFromCSS(COLORS.TEXT)}: #FFFFFF;

`

const LightThemeColors = css`
  ${removeVarFromCSS(COLORS.PRIMARY.d1)}: #D0FBE9;
  ${removeVarFromCSS(COLORS.PRIMARY.d2)}: #A2F7D2;
  ${removeVarFromCSS(COLORS.PRIMARY.d3)}: #73F3BC;
  ${removeVarFromCSS(COLORS.PRIMARY.d4)}: #45EFA5;
  ${removeVarFromCSS(COLORS.PRIMARY.d5)}: #16EB8F;
  ${removeVarFromCSS(COLORS.BACKGROUND.d1)}: #F2F4F4;
  ${removeVarFromCSS(COLORS.BACKGROUND.d2)}: #DCE6E9;
  ${removeVarFromCSS(COLORS.BACKGROUND.d3)}: #000000;
  ${removeVarFromCSS(COLORS.BACKGROUND.gradient)}:   #FFFFFF 0% 0% no-repeat padding-box; opacity: 1;
  ${removeVarFromCSS(COLORS.GRAY.d1)}: #07131C;
  ${removeVarFromCSS(COLORS.GRAY.d2)}: #838996;
  ${removeVarFromCSS(COLORS.WARNING)}: #FEC132;
  ${removeVarFromCSS(COLORS.INFO)}: #58C2D9;
  ${removeVarFromCSS(COLORS.DANGER)}: #E64965;
  ${removeVarFromCSS(COLORS.SUCCESS)}: #58D96D;
  ${removeVarFromCSS(COLORS.TEXT)}: #000000;
  `
  // ${removeVarFromCSS(COLORS.FILTER.light)}: ;
export const CSSColorsVars = ({theme}) => theme === THEMES.DARK ? DarkThemeColors : LightThemeColors;