import { createGlobalStyle, css } from "styled-components";
import { COLORS } from "../common/constants";
import { CSSColorsVars } from "./color-themes";
import { useSelector } from "react-redux";
import CSSReset from "./css-reset";
import {
  MEDIA_DIAMOND,
  MEDIA_GOLD,
  MEDIA_IPAD_PRO,
  MEDIA_PLATINUM,
  MEDIA_SILVER,
} from "./responsive";
import CSSFonts from "./css-fonts";

const Bronze = css`
  & .vs-app {
    font-size: 11px;
    height: 100vh;
    overflow: auto;
    font-family: Almarai, sans-serif;
    color: ${COLORS.GRAY.d1};
    background: ${COLORS.BACKGROUND.gradient};
    &::-webkit-scrollbar {
      width: 0.5em;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px ${COLORS.BACKGROUND.d2};
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${COLORS.GRAY.d2};
      border-radius: 5px;
    }
  }
`;
const BronzeLight = css`
  & .vs-app {
    font-size: 11px;
    height: 100vh;
    overflow: auto;
    font-family: Almarai, sans-serif;
    color: ${COLORS.GRAY.d1};
    background: #fff;
    &::-webkit-scrollbar {
      width: 0.5em;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px ${COLORS.BACKGROUND.d2};
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${COLORS.GRAY.d2};
      border-radius: 5px;
    }
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const IPadPro = css``;

const Diamond = css``;

const ThemedGlobalStyles = createGlobalStyle`
  ${CSSFonts}
  ${CSSReset}
  [data-tooltip]:before {
    width: 0;
  }
  [data-tooltip-right]:hover::before{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% + 5px);
    content:attr(data-tooltip-right);
    color: ${COLORS.GRAY.d2};
    font-size: 10px;
    white-space: nowrap;
    z-index: 100;
  }
  [data-tooltip-left]:hover::before{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: calc(100% + 5px);
    content:attr(data-tooltip-left);
    color: ${COLORS.GRAY.d2};
    font-size: 10px;
    white-space: nowrap;
    z-index: 100;
  }
  [data-tooltip-bottom]:hover::before{
    position: absolute;
    top: calc(100% + 1em);
    transform: translateX(-50%);
    left: 50%;
    content:attr(data-tooltip-bottom);
    color: ${COLORS.GRAY.d2};
    font-size: 10px;
    white-space: nowrap;
    z-index: 100;
  }
  [data-tooltip-top]:hover::before{
    position: absolute;
    bottom: calc(100% + 1em);
    transform: translateX(-50%);
    left: 50%;
    content:attr(data-tooltip-top);
    color: ${COLORS.GRAY.d2};
    font-size: 10px;
    white-space: nowrap;
    z-index: 100;
  }
  .vs-ml-auto{
    margin-left: auto;
  }
  .vs-mr-auto{
    margin-right: auto;
  }
  .vs-mr-15{
      margin-right: 15px;
  }
  .vs-ml-15{
    margin-left: 15px;
  }
  .vs-mb-10{
    margin-bottom: 10px;
  }
  .vs-mb-20{
    margin-bottom: 20px;
  }
  .vs-mb-30{
    margin-bottom: 30px;
  }

  :root {
    ${CSSColorsVars}
  }
  
  body {
    ${Bronze}
    ${MEDIA_SILVER`${Silver}`}
    ${MEDIA_GOLD`${Gold}`}
    ${MEDIA_PLATINUM`${Platinum}`}
    ${MEDIA_IPAD_PRO`${IPadPro}`}
    ${MEDIA_DIAMOND`${Diamond}`}
    
  }
`;

const GlobalStyles = () => {
  const theme = useSelector(({ preferences }) => preferences.theme);
  return <ThemedGlobalStyles theme={theme} />;
};

export default GlobalStyles;
