import { ComponentPropsWithRef } from "react";
import styled, { css } from "styled-components";
import { COLORS } from "../../../common/constants";
import {
  MEDIA_DIAMOND,
  MEDIA_GOLD,
  MEDIA_PLATINUM,
  MEDIA_SILVER,
} from "../../../styles/responsive";
import { ButtonRipples } from "./ButtonRipples";

//States
const CSSHollowGreen = css`
  border-color: ${COLORS.PRIMARY.d5};
  color: ${COLORS.PRIMARY.d5};
  background-color: ${COLORS.BACKGROUND.d2};

  & svg {
    fill: ${COLORS.PRIMARY.d5};
    stroke: ${COLORS.PRIMARY.d5};
    & .pathOne {
      stroke: ${COLORS.PRIMARY.d5};
    }
    & .pathTwo {
      stroke: ${COLORS.GRAY.d1};
    }
  }
`;
const CSSHollowRed = css`
  border-color: ${COLORS.DANGER};
  color: ${COLORS.DANGER};
  background-color: ${COLORS.BACKGROUND.d2};

  & svg {
    fill: ${COLORS.PRIMARY.d5};
    stroke: ${COLORS.PRIMARY.d5};
  }
`;

const CSSHollowGray = css`
  border-color: ${COLORS.GRAY.d1};
  color: ${COLORS.GRAY.d1};
  background-color: ${COLORS.BACKGROUND.d2};

  & svg {
    fill: ${COLORS.GRAY.d1};
    stroke: ${COLORS.GRAY.d1};
  }
`;

const CSSSolidGray = css`
  border-color: ${COLORS.GRAY.d1};
  color: ${COLORS.BACKGROUND.d2};
  background-color: ${COLORS.GRAY.d1};

  & svg {
    fill: ${COLORS.BACKGROUND.d2};
    stroke: ${COLORS.BACKGROUND.d2};
  }
`;

const CSSSolidGreen = css`
  border-color: ${COLORS.PRIMARY.d5};
  color: ${COLORS.BACKGROUND.d2};
  background-color: ${COLORS.PRIMARY.d5};

  & svg {
    fill: ${COLORS.BACKGROUND.d2};
    stroke: ${COLORS.BACKGROUND.d2};
  }
`;
const CSSSpaceBetween = css`
  justify-content: space-between;
`;

const Bronze = css`
  font-size: 18px;
  padding: 0.8333em 1.6667em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  border-radius: 5px;
  font-weight: 100;
  border-width: 2px;
  border-style: solid;
  text-transform: uppercase;
  cursor: pointer;
  overflow: hidden;

  & svg {
    width: 1em;
    height: 1em;
    margin-right: 0.5556em;
    margin-left: 0.5556em;

    & .pathOne {
      stroke-width: 7px;
    }

    & .pathTwo {
      stroke-width: 7px;
    }
  }

  ${({ variant }: buttonMainProps) =>
    variant === "hollow-green" && CSSHollowGreen}
  ${({ variant }: buttonMainProps) => variant === "hollow-red" && CSSHollowRed}
  ${({ variant }: buttonMainProps) =>
    variant === "hollow-gray" && CSSHollowGray}
  ${({ variant }: buttonMainProps) =>
    variant === "solid-green" && CSSSolidGreen}
  ${({ variant }: buttonMainProps) => variant === "solid-gray" && CSSSolidGray}

  ${({ align }: buttonMainProps) =>
    align === "space-between" && CSSSpaceBetween}
`;
const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;
type buttonMainProps = {
  variant:
    | "hollow-gray"
    | "hollow-green"
    | "solid-gray"
    | "solid-green"
    | "hollow-red";
  align?: "center" | "space-between";
} & ComponentPropsWithRef<"button">;

const SButtonMain = styled.button<buttonMainProps>`
  ${Bronze}
  ${MEDIA_SILVER`${Silver}`}
  ${MEDIA_GOLD`${Gold}`}
  ${MEDIA_PLATINUM`${Platinum}`}
  ${MEDIA_DIAMOND`${Diamond}`}
`;

export const ButtonMain = styled(
  ({ children, variant, align = "center", ...props }: buttonMainProps) => {
    return (
      <SButtonMain  className={variant} variant={variant} {...props} align={align}>
        {children}
        <ButtonRipples
         
          color={
            variant === "hollow-gray" || variant === "hollow-green"
              ? COLORS.TEXT
              : COLORS.BACKGROUND.d3
          }
        />
      </SButtonMain>
    );
  }
)``;
