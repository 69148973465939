const DownloadIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={140}
      height={115}
      viewBox="0 0 140 115"
      fill="none"
      {...props}
    >
      <path
        fill="#16EB8F"
        className="single_icon"
        d="m66.658 87.875.049-84.555c0-1.827 1.49-3.302 3.334-3.3 1.843 0 3.331 1.477 3.33 3.304l-.049 84.555c0 1.826-1.49 3.302-3.334 3.3-1.843 0-3.331-1.478-3.33-3.304Z"
      />
      <path
        fill="#16EB8F"
        className="single_icon"
        d="M36.518 57.965c0-.845.326-1.689.975-2.338a3.347 3.347 0 0 1 4.711.003l27.789 27.576 27.82-27.545a3.347 3.347 0 0 1 4.712.003 3.276 3.276 0 0 1-.003 4.67L72.348 90.21a3.347 3.347 0 0 1-4.712-.003l-30.14-29.91a3.277 3.277 0 0 1-.972-2.338l-.006.006Z"
      />
      <path
        fill="#16EB8F"
        className="single_icon"
        d="m.011 95.7.028-49.164c.006-10.598 8.71-19.215 19.401-19.21l20.422.012c1.843.002 3.331 1.479 3.33 3.305 0 1.827-1.49 3.302-3.334 3.301l-20.421-.011c-7.018-.004-12.73 5.65-12.733 12.607l-.029 49.164c-.004 6.957 5.701 12.618 12.719 12.622l101.169.057c7.018.004 12.729-5.651 12.733-12.607l.029-49.165c.004-6.956-5.701-12.617-12.719-12.621l-20.422-.012c-1.842 0-3.33-1.478-3.33-3.304.001-1.827 1.491-3.302 3.334-3.302l20.422.012c10.692.006 19.385 8.633 19.379 19.231l-.028 49.165c-.006 10.598-8.71 19.215-19.401 19.209l-101.164-.057C8.704 114.926.01 106.299.016 95.701H.012Z"
      />
    </svg>
  );
};

export default DownloadIcon;
