import {
  getLangCasing,
  getLangDirection,
  LANGUAGES,
} from "../../common/constants";
import { preferences, preferencesActions, theme } from "../../types/types";
const initialTheme = (localStorage.getItem("USER_THEME") as theme) || "dark";

//Todo get initial state from local storage or from the server

const defaultPreferences: preferences = {
  lang: {
    name: "en-us",
    dir: getLangDirection("en-us"),
    preserveCasing: getLangCasing(LANGUAGES.ENGLISH_US),
  },
  theme: initialTheme,
  locationPermission: false,
};

export const preferencesReducer = (
  preferences: preferences = defaultPreferences,
  action: preferencesActions
): preferences => {
  if (action.type === "CHANGE_LOCATION_PERMISSION") {
    return { ...preferences, locationPermission: action.payload };
  }
  if (action.type === "CHANGE_THEME") {
    return { ...preferences, theme: action.payload };
  }
  if (action.type === "CHANGE_LANGUAGE") {
    const langName = action.payload;
    const langDir = getLangDirection(langName);
    const langCasing = getLangCasing(langName);
    return {
      ...preferences,
      lang: { name: langName, dir: langDir, preserveCasing: langCasing },
    };
  }
  return preferences;
};
